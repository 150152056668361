<script setup>
import { ref, onMounted, computed } from 'vue';
import axios from 'axios';
import OfSpinner from '@/components/OfSpinner.vue'
import md5 from 'js-md5'

const api = process.env.VUE_APP_API

const sessionGuid = ref(null)

const countryCode = ref(null)

const sessionData = ref(null)

const hasError = ref(false)

const cardHolder = ref('')

const cardNumber = ref('')

const expMonth = ref('')

const expYear = ref('')

const cvc = ref('')

const loading = ref(false);

const paymentError = ref(null)

const responseHTML = ref(null);

function maskNumber(value, limit, separator) {
  let output = [];
  for (let i = 0; i < value.length; i++) {
    if (i !== 0 && i % limit === 0) {
      output.push(separator);
    }

    output.push(value[i]);
  }

  return output.join("");
}
function unmaskNumber(value) {
  return value.replace(/[^\d]/g, '');
}
function numberChange() {
  cardNumber.value = unmaskNumber(cardNumber.value);

  cardNumber.value = maskNumber(cardNumber.value, 4, ' ');
}

function enforceRule(rule, e) {
  if (rule === 'digits') {
    if ([191, 219].includes(e.keyCode)) {
      e.preventDefault();
    } else if ((e.keyCode === 65 || e.keyCode === 86 || e.keyCode === 67) && (e.ctrlKey === true || e.metaKey === true)) {
      return;
    } else if ([8, 46, 9, 27, 13, 35, 36, 37, 39].includes(e.keyCode)) {
      return;
    } else if (e.keyCode > 47 && e.keyCode < 58) {
      return;
    } else if (e.keyCode > 95 && e.keyCode < 106) {
      return;
    }

    e.preventDefault();

  } else if (rule === "text") {

    const keyCode = (e.keyCode ? e.keyCode : e.which);

    if ((keyCode > 47 && keyCode < 58) || (keyCode > 95 && keyCode < 106)) {
      e.preventDefault();
    }

  }
}
function submit() {
  if (cardNumber.value.length != 19) {
    paymentError.value = 'Card number must be exactly 16 characters long';
  } else {
    paymentError.value = null;

    const data = { cardNumber: cardNumber.value.replace(/\s/g, ''), expMonth: expMonth.value, expYear: expYear.value, cvc: cvc.value }

    const jsonData = JSON.stringify(data);

    loading.value = true;

    axios.post(
      sessionData.value.submitUrl,
      jsonData,
      {
        headers: {
          'Content-Type': 'application/json',
          'Content-MD5': md5(jsonData)
        }
      }).then((response) => {

        loading.value = false;

        responseHTML.value = response.data;

        const i = 0;

        const interval = setInterval(() => {
          if (document.querySelector('#submitBtn[value*="click here"]')) {
            document.querySelector('#submitBtn[value*="click here"]').click()
            clearInterval(interval);
          }

          if (i >= 5) {
            clearInterval(interval);
          }
        }, 300);

        console.log(response);

      }).catch((error) => {
        loading.value = false;

        paymentError.value = error.response && error.response.data.errorMessage ? error.response.data.errorMessage : 'Unexpected error occured';

      })
  }
}
function autotab(e, max) {
  if (e.target.value.length == max) {

    const formElements = Array.from(document.querySelectorAll('#payment-form input'));
    const focusedIndex = formElements.indexOf(e.target);
    const nextElement = formElements[focusedIndex + 1];

    if (nextElement) {
      nextElement.focus()
    }

  }
}
const validMonth = computed(() => {
  return !expMonth.value.length || expMonth.value.match(/^(0[1-9]|1[0-2])$/) ? true : false;
})

onMounted(() => {
  const params = new URLSearchParams(window.location.search);

  sessionGuid.value = params.get('sessionGuid');

  countryCode.value = params.get('countryCode') ?? 'IS';

  if (sessionGuid.value) {

    loading.value = true;

    const query = `${api}/session/${sessionGuid.value}/paymentPageInfo?countryCode=${countryCode.value}`;

    axios.get(
      query, {
      headers: {
      }
    }).then((response) => {

      loading.value = false;

      console.log(response.data);

      sessionData.value = response.data;

    }).catch((error) => {

      loading.value = false;

      console.log(error);

      hasError.value = true;

    })
  }
})

</script>
<template>
  <div v-if="!sessionGuid">
    Missing session request
  </div>

  <div v-if="hasError">
    Error loading session data
  </div>

  <div v-if="sessionData && !responseHTML" class="container">

    <div class="titles">
      <h1>{{ sessionData.title }}</h1>
      <small>{{ sessionData.secondTitle }}</small>
    </div>
    <form id="payment-form" @submit.prevent="submit">
      <div class="card-form-container">


        <label id="card-holder-label" for="card-holder">
          {{ sessionData.texts.cardHolderName }}
        </label>

        <input v-model="cardHolder" id="card-holder" @keydown="enforceRule('text', $event)" required autofocus>

        <label id="card-number-label" for="card-number">
          {{ sessionData.texts.cardNumber }}
        </label>

        <input v-model="cardNumber" id="card-number" @input="[numberChange(), autotab($event, 19)]" maxlength="19"
          @keydown="enforceRule('digits', $event)" required>

        <label id="card-month-label" for="card-month">
          {{ sessionData.texts.expMonth }}
        </label>

        <label id="card-year-label" for="card-year">
          {{ sessionData.texts.expYear }}
        </label>


        <label id="card-code-label" for="card-code">
          {{ sessionData.texts.cvc }}
        </label>

        <input v-model="expMonth" id="card-month" type="tel" maxlength="2" minlength="2"
          :class="{ invalid: !validMonth }" @input="autotab($event, 2)" @keydown="enforceRule('digits', $event)"
          placeholder="MM" required>

        <input v-model="expYear" type="tel" id="card-year" maxlength="2" minlength="2" @input="autotab($event, 2)"
          @keydown="enforceRule('digits', $event)" placeholder="YY" required>

        <input v-model="cvc" type="tel" id="card-code" maxlength="4" @keydown="enforceRule('digits', $event)" required>

        <button type="submit">{{ sessionData.texts.submitBtn }}</button>

        <p v-if="paymentError" class="payment-error">{{ paymentError }}</p>

      </div>


    </form>

    <div class="cancel">
      <form :action="sessionData.cancelUrl" method="post">
        <a><button type="submit">{{ sessionData.texts.cancelBtn }}</button></a>
      </form>
    </div>
  </div>

  <div v-if="responseHTML" v-html="responseHTML">

  </div>
  <OfSpinner v-if="loading" />
</template>
<style scoped>
* {
  margin: 0;
}

.container {}

form#payment-form {
  background-color: #F5F6F8;
}

.titles {
  max-width: 350px;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 2rem;
}

.card-form-container {
  display: grid;
  grid-template-columns: repeat(12, minmax(0, 1fr));
  grid-column-gap: 0.5rem;
  grid-row-gap: .3rem;
  max-width: 350px;
  padding-top: 1rem;
  padding-bottom: 2rem;
  margin-right: auto;
  margin-left: auto;

  -ms-grid-template-columns: repeat(12, minmax(0, 1fr));
  -ms-grid-column-gap: 0.5rem;
  -ms-grid-row-gap: .3rem;
}

.card-form-container label {
  font-weight: 600;
}

.card-form-container input {
  border: 1px solid #bbb;
  border-radius: 4px;
  background: #fff;
  height: 2rem;
  outline: none;
  padding-left: 5px;
  padding-top: 5px;
}

.card-form-container input.invalid {
  border-color: red;
}

.card-form-container label#card-holder-label {
  grid-column: span 12/span 12;

  -ms-grid-column: span 12/span 12;
}

.card-form-container input#card-holder {
  grid-column: span 12/span 12;
  margin-bottom: 0.6rem;

  -ms-grid-column: span 12/span 12;
}

.card-form-container label#card-number-label {
  grid-column: span 12/span 12;

  -ms-grid-column: span 12/span 12;
}

.card-form-container input#card-number {
  margin-bottom: 0.6rem;
  grid-column: span 12/span 12;

  -ms-grid-column: span 12/span 12;
}

.card-form-container label#card-month-label {
  grid-column: span 3/span 3;

  -ms-grid-column: span 3/span 3;
}

.card-form-container label#card-year-label {
  grid-column: span 3/span 3;

  -ms-grid-column: span 3/span 3;

}

.card-form-container label#card-code-label {
  margin-left: 1rem;

  grid-column: span 6/span 6;

  -ms-grid-column: span 6/span 6;
}

.card-form-container input#card-month {
  grid-column: span 3/span 3;

  -ms-grid-column: span 3/span 3;
}

.card-form-container input#card-year {
  grid-column: span 3/span 3;

  -ms-grid-column: span 3/span 3;
}

.card-form-container input#card-code {
  margin-left: 1rem;

  grid-column: span 6/span 6;

  -ms-grid-column: span 6/span 6;
}

.card-form-container button {
  grid-column: span 12/span 12;
  margin-top: 2rem;
  margin-bottom: 1rem;
  color: #fff;
  background-color: #151515;
  border-color: #151515;
  width: 100%;
  padding: 11px;
  line-height: 16px;
  font-size: 16px;

  display: inline-block;
  margin-bottom: 0;
  font-weight: normal;
  text-align: center;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  border-radius: 4px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  -ms-grid-column: span 12/span 12;
}

.cancel {
  max-width: 350px;
  margin-right: auto;
  margin-left: auto;
}

.cancel button {
  width: 100%;
  padding: 11px;
  line-height: 16px;
  font-size: 16px;
  margin: 30px 0;
  color: #999;
  background-color: #fff;
  border: 1px solid #ccc;

  display: inline-block;
  margin-bottom: 0;
  font-weight: normal;
  text-align: center;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  white-space: nowrap;
  border-radius: 4px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.cancel button:hover {
  color: #c9302c;
  background: #f9e3e3;
  border-color: #f4cdcd;
}

.cancel button:active {
  -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}

p.payment-error {
  color: #c9302c;
  margin-top: 0.5rem;
  grid-column: span 12/span 12;
  -ms-grid-column: span 12/span 12;
}

p.success-message {
  color: #38B249;
}

#iframe-wrapper {
  max-width: 500px;
  max-height: 600px;
  text-align: center;
  margin: 0 auto;
}
</style>
<style>
body {
  font-family: Arial, Helvetica, sans-serif;
}
</style>
